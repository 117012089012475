import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const IntegerFormat = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onChange={e => {
        // TODO ---> leading 0 issue--->format and remove all 0
        onChange(e);
      }}
      decimalScale={0}
    />
  );
});

export const NaturalNumberFormat = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      getInputRef={ref}
      onChange={e => {
        // TODO ---> leading 0 issue--->format and remove all 0
        onChange(e);
      }}
      decimalScale={0}
    />
  );
});

IntegerFormat.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default IntegerFormat;
