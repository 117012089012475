import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { Typography, IconButton, Paper, Dialog, AppBar, Toolbar, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/EditOutlined';
import {
  selectMailMessage,
  deleteCmpAction,
  selectSMSMessage,
  selectNotifyMessage,
  selectXplorNotifMessage,
} from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import getApiCaller from 'src/utils/apiClientCaller';
import { loaderActions } from 'src/reducers/loader';

import CustCard from './ActionCard';
import TestForm from 'src/components/common/TestForm';

import ActionType from 'src/enums/actionType';
import { COMMERCIAL_RELAUNCH, MESSAGE_EDITOR } from './CampaignMessage';
import { useAlert } from 'src/hooks';
import { selectCommercialRelaunchContent } from 'src/reducers/commercialCampaignEditorActions';
import useFetch from 'src/hooks/useFetch';

const useStyles = makeStyles()(theme => ({
  appBar: {
    position: 'relative',
  },
  testBtn: { margin: '2px', color: theme.palette.primary.contrastText },
  editBtn: {
    backgroundColor: theme.palette.common.black,
    margin: '2px',
    '&:hover': {
      backgroundColor: theme.palette.common.black,
      opacity: 0.9,
    },
  },
  deleteBtn: {
    margin: '2px',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      opacity: 0.9,
    },
  },
}));

function ActionEditorHandler({
  loadFieldsAndContent,
  action,
  doDeleteCmpAction,
  setLoader,
  goToCmpMsgStep,
  actionTypeId,
  rmId,
  isWritable,
  lang,
  currentAppLn,
  doSelectSMSMessage,
  doSelectMailMessage,
  doSelectNotifyMessage,
  doSelectXplorNotifMessage,
  doSelectCommercialRelaunchContent,
}) {
  const [testFormIsVisible, showTestForm] = useState(false);
  const { classes } = useStyles();
  const { showAlert } = useAlert();

  const isActionCommercialRelaunch = action.actionTypeId === ActionType.PROSPECTING.id;
  const { data: commercialRelaunchContent } = useFetch(
    { url: `/message/${action.messageId}` },
    { enabled: isActionCommercialRelaunch }
  );

  const loadContentFn = async processedFields => {
    switch (action.actionTypeId) {
      case ActionType.SMS.id:
        await doSelectSMSMessage(action, processedFields.fieldsModels, showAlert);
        break;
      case ActionType.EMAIL.id:
      case ActionType.EMAIL_COACH.id:
      case ActionType.EMAIL_SPONSORSHIP.id:
      case ActionType.EMAIL_INVITATION.id:
        await doSelectMailMessage(
          action,
          processedFields.fieldsModels,
          action.actionTypeId,
          showAlert
        );
        break;
      case ActionType.MEMBER_NOTIFICATION.id:
        await doSelectNotifyMessage(
          action,
          processedFields.fieldsModels,
          action.actionTypeId,
          showAlert
        );
        break;
      case ActionType.PUSH_XPLORACTIVE.id:
        await doSelectXplorNotifMessage(
          action,
          processedFields.fieldsModels,
          action.actionTypeId,
          showAlert
        );
        break;
      default:
        break;
    }
  };

  async function handleMessageSelect() {
    if (action.actionTypeId === ActionType.PROSPECTING.id) {
      await doSelectCommercialRelaunchContent(
        action,
        commercialRelaunchContent,
        action.actionTypeId,
        showAlert
      );
      goToCmpMsgStep(COMMERCIAL_RELAUNCH);
    } else {
      loadFieldsAndContent(async processedFields => {
        await loadContentFn(processedFields);
        goToCmpMsgStep(MESSAGE_EDITOR, {
          fields: processedFields,
          actionTypeId: action.actionTypeId,
        });
      });
    }
  }
  return (
    <>
      <Dialog open={testFormIsVisible} fullWidth>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h5" color="inherit" style={{ flex: 1 }}>
              {utils.getLang('smartmessaging.campaignEditor.testAction.popupTitle')}
            </Typography>
            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                showTestForm(false);
              }}
              className={classes.testBtn}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            minHeight: '300px',
          }}
        >
          <TestForm
            rmId={rmId}
            includeWizVille
            campaignActionId={action && action.id}
            actionTypeId={actionTypeId}
            currentAppLn={currentAppLn}
          />
        </div>
      </Dialog>
      <CustCard title={utils.getLang(lang.title[actionTypeId])}>
        <Paper
          style={{ height: '200px', width: '160px', display: 'flex', flexDirection: 'column' }}
        >
          <Typography
            color="primary"
            style={{
              wordWrap: 'normal',
              padding: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {utils.getLang(lang.createText[actionTypeId])}
          </Typography>
          <div style={{ flex: 1 }}>
            <IconButton
              onClick={event => {
                event.preventDefault();
                setLoader(true);
                handleMessageSelect().finally(() => setLoader(false));
              }}
              className={classes.editBtn}
              color="secondary"
              size="small"
            >
              <EditIcon />
            </IconButton>
            {isWritable && (
              <IconButton
                onClick={event => {
                  event.preventDefault();
                  doDeleteCmpAction(action.id, showAlert);
                }}
                className={classes.deleteBtn}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          {![
            ActionType.MEMBER_NOTIFICATION.id,
            ActionType.PUSH_XPLORACTIVE.id,
            ActionType.PROSPECTING.id,
          ].includes(actionTypeId) && (
            <div style={{ padding: '12px', display: 'grid' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={event => {
                  event.preventDefault();
                  showTestForm(true);
                }}
              >
                {utils.getLang('smartmessaging.contentEditor.testContent')}
              </Button>
            </div>
          )}
        </Paper>
      </CustCard>
    </>
  );
}

ActionEditorHandler.propTypes = {
  doSelectSMSMessage: PropTypes.func.isRequired,
  doSelectMailMessage: PropTypes.func.isRequired,
  doSelectNotifyMessage: PropTypes.func.isRequired,
  doSelectXplorNotifMessage: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  loadFieldsAndContent: PropTypes.func.isRequired,
  doDeleteCmpAction: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  actionTypeId: PropTypes.number.isRequired,
  rmId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  lang: PropTypes.object.isRequired,
  currentAppLn: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  rmTypeId: state.campaignEditor.editedCampaign.requestModelTypeId,
  currentAppLn: state.app.currentLanguage,
});

const actionCreators = {
  doDeleteCmpAction: deleteCmpAction,
  doSelectMailMessage: selectMailMessage,
  doSelectSMSMessage: selectSMSMessage,
  doSelectNotifyMessage: selectNotifyMessage,
  doSelectXplorNotifMessage: selectXplorNotifMessage,
  doSelectCommercialRelaunchContent: selectCommercialRelaunchContent,
  setLoader: visible => loaderActions.setLoader(visible),
  doGetApiCaller: getApiCaller,
};

export default connect(mapStateToProps, actionCreators)(ActionEditorHandler);
