import utils from 'src/utils/utils';

export const EBeantype = {
  ACTIVITY: {
    id: 0,
    key: 'ACTIVITY',
    apiSentence: 'activity/all',
    externalFKFieldName: 'activityId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  COACH: {
    id: 2,
    key: 'COACH',
    apiSentence: 'coach/all',
    externalFKFieldName: 'coachId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: ({ familyName }) => familyName,
    getLabel: coach => `${coach.givenName} ${coach.familyName}`,
  },
  ROOM: {
    id: 3,
    key: 'ROOM',
    apiSentence: 'studio/all',
    externalFKFieldName: 'studioId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CLUB: {
    id: 16,
    key: 'CLUB',
    apiSentence: 'club/all',
    externalFKFieldName: 'clubId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  TYPEOFABO: {
    id: 142,
    key: 'TYPEOFABOY',
    apiSentence: 'typeofabo/all',
    externalFKFieldName: 'typeOfAboId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  PRODUCT: {
    id: 233,
    key: 'PRODUCT',
    apiSentence: 'product/all',
    externalFKFieldName: 'productId',
    getSecondaryDisplayInfo: ({ code }) => code || '**',
    getSubFieldDisplayInfo: product => product.name,
    getLabel: ({ name }) => name,
  },
  OFFER: {
    id: 21,
    key: 'OFFER',
    apiSentence: 'offer/all',
    externalFKFieldName: 'offerId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  SEPA_REJECTION_CODE: {
    id: 17,
    key: 'SEPA_REJECTION_CODE',
    apiSentence: 'separejectioncode/directdebit',
    externalFKFieldName: 'sepaRejectionCodeId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ code }) => utils.getLang(`smartmessaging.sepaErrorCode.label.${code}`),
  },
  SEPA_REJECTION_CODE_GROUP: {
    id: 18,
    key: 'SEPA_REJECTION_CODE_GROUP',
    apiSentence: 'separejectioncodegroup/all',
    externalFKFieldName: 'sepaRejectionCodeGroupId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ id }) => utils.getLang(`smartmessaging.sepaErrorCodeGroup.label.${id}`),
    // getLabel: ({ name }) => name,
  },
  COUNTER_UNIT: {
    id: 19,
    key: 'COUNTER_UNIT',
    apiSentence: 'counterunit/all',
    externalFKFieldName: 'counterUnitId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CONTACT_TAG_REFERENTIAL: {
    id: 20,
    key: 'CONTACT_TAG_REFERENTIAL',
    apiSentence: 'contacttagreferential/all',
    externalFKFieldName: 'contactTagReferentialId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  ZONE: {
    id: 368,
    key: 'ZONE',
    apiSentence: 'zone/all',
    externalFKFieldName: 'zoneId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CROSSINGPOINT: {
    id: 370,
    key: 'CROSSINGPOINT',
    apiSentence: 'crossingpoint/all',
    externalFKFieldName: 'crossingpointId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  ENTRY: {
    id: 369,
    key: 'ENTRY',
    apiSentence: 'entry/all',
    externalFKFieldName: 'entryId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CLUB_TAG_REFERENTIAL: {
    id: 400,
    key: 'CLUB_TAG_REFERENTIAL',
    apiSentence: 'clubtagreferential/all',
    externalFKFieldName: 'clubTagReferentialId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  COMPANY: {
    id: 372,
    key: 'COMPANY',
    apiSentence: 'company/all',
    externalFKFieldName: 'companyId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  USER: {
    id: 373,
    key: 'USER',
    apiSentence: 'user/all',
    externalFKFieldName: 'userId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ givenName, familyName }) => `${familyName} ${givenName}`,
  },
  REFERENTIAL_SUSPENSION: {
    id: 374,
    key: 'REFERENTIAL_SUSPENSION',
    apiSentence: 'referential/suspension',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  REFERENTIAL_MOTIVATION: {
    id: 375,
    key: 'REFERENTIAL_MOTIVATION',
    apiSentence: 'referential/motivation',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  REFERENTIAL_GOAL: {
    id: 376,
    key: 'REFERENTIAL_GOAL',
    apiSentence: 'referential/goal',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  REFERENTIAL_SOURCE: {
    id: 377,
    key: 'REFERENTIAL_SOURCE',
    apiSentence: 'referential/source',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  REFERENTIAL_CANCELLATION: {
    id: 378,
    key: 'REFERENTIAL_CANCELLATION',
    apiSentence: 'referential/cancellation',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  CIVILITY: {
    id: 379,
    key: 'CIVILITY',
    apiSentence: 'civility',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ names }) => names[Object.keys(names)[0]],
  },
  CARD_SEPA_REJECTION_CODE: {
    id: 380,
    key: 'CARD_SEPA_REJECTION_CODE',
    apiSentence: 'separejectioncode/card',
    externalFKFieldName: 'sepaRejectionCodeId',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  BLOCKING_REASON: {
    id: 381,
    key: 'BLOCKING_REASON',
    apiSentence: 'blockingreason',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ name }) => name,
  },
  COMMERCIAL_RELAUNCH_TYPE: {
    id: 382,
    key: 'COMMERCIAL_RELAUNCH_TYPE',
    apiSentence: 'prospectingtype/all',
    getSecondaryDisplayInfo: () => {},
    getSubFieldDisplayInfo: () => {},
    getLabel: ({ title }) => title,
  },
};

export const getBeanTypeById = id => {
  const beantypes = Object.values(EBeantype);
  let eBeanType;
  beantypes.every(bean => {
    if (bean.id === id) {
      eBeanType = bean;
      return false;
    }
    return true;
  });
  return eBeanType;
};
