import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, IconButton, Paper, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import ActionType from 'src/enums/actionType';
import utils from 'src/utils/utils';
import getApiCaller from 'src/utils/apiClientCaller';
import apiClient from 'src/utils/apiClient';
import { loaderActions } from 'src/reducers/loader';

import massActionSelectors from 'src/selectors/massAction';
import {
  checkContentFieldsAndSelectedColumns,
  resetContentEditorFlowData,
  deleteCmpAction,
} from 'src/reducers/massAction';

import CustCard from 'src/components/common/ActionCard';
import { CONTENT } from '../MassActionContent';
import { COMMERCIAL_RELAUNCH, CONTENT_EDITOR } from './MassActionMessage';
import { makeStyles } from 'tss-react/mui';
import { useAlert } from 'src/hooks';
import { selectCommercialRelaunchContent } from 'src/reducers/massAction/commercialMassActionEditorActions';

const mapStateToProps = state => ({
  contentFields: massActionSelectors.getContentFields(state),
});

const actionCreators = {
  doDeleteCmpAction: deleteCmpAction,
  setLoader: visible => loaderActions.setLoader(visible),
  doGetApiCaller: getApiCaller,
  doResetContentEditorFlowData: resetContentEditorFlowData,
  updateCurrentCmpAction: cmpAction => ({ type: 'UPDATE_CURRENT_CMP_ACTION', value: cmpAction }),
  doCheckContentFieldsAndSelectedColumns: checkContentFieldsAndSelectedColumns,
  doSelectCommercialRelaunchContent: selectCommercialRelaunchContent,
};

const propTypes = {
  doDeleteCmpAction: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  actionTypeId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  onEditMessage: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

const useStyles = makeStyles()(theme => ({
  deleteBtn: {
    margin: '2px',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      opacity: 0.9,
    },
  },
}));

const ActionEditor = ({
  action,
  doDeleteCmpAction,
  actionTypeId,
  isWritable,
  onEditMessage,
  deleteAction,
  lang,
}) => {
  const { classes } = useStyles();
  const { showAlert } = useAlert();

  return (
    <>
      <CustCard style={{ overflow: 'hidden' }} title={utils.getLang(lang.title[actionTypeId])}>
        <Paper
          style={{ height: '200px', width: '160px', display: 'flex', flexDirection: 'column' }}
        >
          <Typography
            color="primary"
            style={{
              wordWrap: 'normal',
              padding: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {utils.getLang(lang.createText[actionTypeId])}
          </Typography>
          <div style={{ flex: 1 }}>
            {isWritable && (
              <IconButton
                onClick={event => {
                  event.preventDefault();
                  doDeleteCmpAction(action.id, deleteAction, showAlert);
                }}
                className={classes.deleteBtn}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          <div style={{ padding: '12px', display: 'grid' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={event => {
                event.preventDefault();
                onEditMessage();
              }}
            >
              {utils.getLang('smartmessaging.contentEditor.useContent')}
            </Button>
          </div>
        </Paper>
      </CustCard>
    </>
  );
};

ActionEditor.propTypes = propTypes;

function ActionEditorHandler(props) {
  const { showAlert } = useAlert();

  async function onEditMessage() {
    props.doResetContentEditorFlowData();
    const apiCaller = props.doGetApiCaller(showAlert);
    props.setLoader(true);

    const message = await apiCaller(apiClient.getMessage, [props.action.messageId]).catch(() =>
      props.setLoader(false)
    );

    if (props.actionTypeId === ActionType.PROSPECTING.id) {
      props.setLoader(false);
      await props.doSelectCommercialRelaunchContent(props.action, message, props.actionTypeId);
      props.updateCurrentCmpAction(props.action);
      props.goToStep(CONTENT, {
        actionId: props.action.id,
        actionType: props.actionTypeId,
        initialStep: COMMERCIAL_RELAUNCH,
      });
      return;
    }

    const content = await apiCaller(apiClient.get, [
      utils.link(message.contentStoredFileId),
    ]).finally(() => props.setLoader(false));

    const { object } = message;

    props.doCheckContentFieldsAndSelectedColumns(
      props.actionTypeId === ActionType.SMS.id ? content : [object, content],
      props.actionTypeId,
      contentFields => {
        props.updateCurrentCmpAction(props.action);
        props.goToStep(CONTENT, {
          actionType: props.actionTypeId,
          actionId: props.action.id,
          initialStep: CONTENT_EDITOR,
          initialExtraProps: {
            fields: contentFields,
            content,
            object,
          },
        });
      },
      showAlert
    );
  }

  const [testFormIsVisible, showTestForm] = useState(false);

  return <ActionEditor {...props} {...{ onEditMessage, testFormIsVisible, showTestForm }} />;
}

ActionEditorHandler.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  deleteAction: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired,
  doCheckContentFieldsAndSelectedColumns: PropTypes.func.isRequired,
  updateCurrentCmpAction: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  doResetContentEditorFlowData: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(ActionEditorHandler);
