import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';
import ActionType from 'src/enums/actionType';
import apiClient from 'src/utils/apiClient';

export const selectCommercialRelaunchContent = (
  action,
  message,
  actionTypeId
) => async dispatch => {
  const commercialRelaunchContent = message ? JSON.parse(message.prospectingContent) : {};

  dispatch({
    type: 'ON_MASS_ACTION_RECEIVE_COMMERCIAL_RELAUNCH_CONFIG',
    value: {
      id: action.id,
      messageId: message.id ?? null,
      type: actionTypeId,
      ...commercialRelaunchContent,
      language: action.language,
    },
  });
};

const onReceiveCommercialRelaunchConfig = (state, { value }) => ({
  ...state,
  commercialRelaunch: {
    ...state.commercialRelaunch,
    id: value.id || null,
    comment: value.comment ?? null,
    date: value.date ?? '0',
    defaultProspectingState: value.defaultProspectingState ?? null,
    messageId: value.messageId ?? null,
    prospectingState: value.prospectingState ?? null,
    prospectingType: value.prospectingType ?? null,
    salePersonId: value.salePersonId ?? null,
    type: value.type,
    language: value.language || null,
  },
  contentValidity: contentEditorUtils.checkCommercialRelaunchContentValidity(value),
});

const onCommercialRelaunchConfigChange = (state, { nextValue }) => ({
  ...state,
  commercialRelaunch: {
    ...state.commercialRelaunch,
    id: nextValue.id || null,
    comment: nextValue.comment ?? null,
    date: nextValue.date ?? '0',
    defaultProspectingState: nextValue.defaultProspectingState ?? null,
    prospectingState: nextValue.prospectingState ?? null,
    prospectingType: nextValue.prospectingType ?? null,
    salePersonId: nextValue.salePersonId ?? null,
    type: nextValue.type,
    language: nextValue.language || null,
  },
  contentValidity: contentEditorUtils.checkCommercialRelaunchContentValidity(nextValue),
  contentHasChanges: true,
});

export const onSaveCommercialRelaunchAction = async (
  modifiedContent,
  campaignId,
  messageId,
  actionId,
  callApi
) => {
  const {
    comment,
    date,
    defaultProspectingState,
    prospectingState,
    prospectingType,
    salePersonId,
  } = modifiedContent;

  const message = await callApi(apiClient.insertOrUpdateMessage, [
    {
      id: messageId,
      prospectingContent: JSON.stringify({
        comment,
        date,
        defaultProspectingState,
        prospectingState,
        prospectingType,
        salePersonId,
      }),
    },
  ]);
  const campaignAction = await callApi(apiClient.insertOrUpdateCampaignAction, [
    {
      id: actionId || null,
      actionTypeId: ActionType.PROSPECTING.id,
      campaignId,
      messageId: message.id,
      language: modifiedContent.language,
    },
  ]);
  return campaignAction;
};

export default {
  ON_MASS_ACTION_RECEIVE_COMMERCIAL_RELAUNCH_CONFIG: onReceiveCommercialRelaunchConfig,
  ON_MASS_ACTION_COMMERCIAL_RELAUNCH_CONFIG_CHANGE: onCommercialRelaunchConfigChange,
  ON_MASS_ACTION_SELECT_COMMERCIAL_RELAUNCH_CONTENT: selectCommercialRelaunchContent,
};
