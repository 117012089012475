import { InitialDataFunction, useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { API_BASE_URL, doAuthentifiedReq } from 'src/utils/apiClient';

function useFetch<T>(config: AxiosRequestConfig, options?: Partial<InitialDataFunction<any>>) {
  return useQuery({
    ...options,
    select(data): T {
      return data.data;
    },
    queryKey: [config],
    queryFn: () => doAuthentifiedReq({ method: 'get', url: `${API_BASE_URL}${config.url}` }),
  });
}

export default useFetch;
