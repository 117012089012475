import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Typography, IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
  checkContentFieldsAndSelectedColumns,
  resetContentEditorFlowData,
} from 'src/reducers/massAction';

import utils from 'src/utils/utils';
import CustCard from 'src/components/common/ActionCard';
import { CONTENT } from '../MassActionContent';
import { COMMERCIAL_RELAUNCH, CONTENT_EDITOR, TEMPLATE_SELECTOR } from './MassActionMessage';
import ActionType from 'src/enums/actionType';
import { useAlert } from 'src/hooks';

const useStyles = makeStyles()(theme => ({
  addBtn: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.contrastText,
  },
}));

const ActionCreator = ({
  actionTypeId,
  goToStep,
  doResetContentEditorFlowData,
  lang,
  doCheckContentFieldsAndSelectedColumns,
  updateCurrentCmpAction,
}) => {
  const { classes } = useStyles();
  const { showAlert } = useAlert();
  const currentAppLn = useSelector(state => state.app.currentLanguage);
  const dispatch = useDispatch();

  const onCreateXplorActiveNotif = useCallback(() => {
    doCheckContentFieldsAndSelectedColumns(
      '',
      actionTypeId,
      contentFields => {
        updateCurrentCmpAction({ language: currentAppLn.shortName || 'FR' });
        goToStep(CONTENT, {
          actionType: actionTypeId,
          actionId: null,
          initialStep: CONTENT_EDITOR,
          initialExtraProps: {
            fields: contentFields,
            content: '',
            object: '',
          },
        });
      },
      showAlert
    );
  }, [
    actionTypeId,
    currentAppLn.shortName,
    doCheckContentFieldsAndSelectedColumns,
    goToStep,
    showAlert,
    updateCurrentCmpAction,
  ]);

  const onCreateAddCommercialRelaunch = useCallback(() => {
    updateCurrentCmpAction({ language: currentAppLn.shortName || 'FR' });
    dispatch({
      type: 'ON_MASS_ACTION_RECEIVE_COMMERCIAL_RELAUNCH_CONFIG',
      value: {
        id: null,
        comment: null,
        date: 0,
        prospectingState: null,
        prospectingType: null,
        salePersonId: null,
        type: actionTypeId,
        language: currentAppLn.shortName,
      },
    });

    goToStep(CONTENT, {
      fields: {},
      actionType: actionTypeId,
      initialStep: COMMERCIAL_RELAUNCH,
    });
  }, [actionTypeId, currentAppLn.shortName, dispatch, goToStep, updateCurrentCmpAction]);

  return (
    <CustCard title={utils.getLang(lang.title[actionTypeId])}>
      <Paper style={{ height: '200px', width: '160px', display: 'inline-block' }}>
        <Typography
          color="primary"
          style={{
            wordWrap: 'normal',
            padding: '8px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {utils.getLang(lang.createText[actionTypeId])}
        </Typography>

        <IconButton
          onClick={e => {
            e.preventDefault();
            doResetContentEditorFlowData();
            if (ActionType.PUSH_XPLORACTIVE.id === actionTypeId) {
              onCreateXplorActiveNotif();
            } else if (ActionType.PROSPECTING.id === actionTypeId) {
              onCreateAddCommercialRelaunch();
            } else {
              goToStep(CONTENT, {
                actionType: actionTypeId,
                initialStep: TEMPLATE_SELECTOR,
              });
            }
          }}
          className={classes.addBtn}
          size="small"
        >
          <AddIcon />
        </IconButton>
      </Paper>
    </CustCard>
  );
};

ActionCreator.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  doResetContentEditorFlowData: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  doCheckContentFieldsAndSelectedColumns: PropTypes.func.isRequired,
  updateCurrentCmpAction: PropTypes.func.isRequired,
};

const actionCreators = {
  doResetContentEditorFlowData: resetContentEditorFlowData,
  updateCurrentCmpAction: cmpAction => ({ type: 'UPDATE_CURRENT_CMP_ACTION', value: cmpAction }),
  doCheckContentFieldsAndSelectedColumns: checkContentFieldsAndSelectedColumns,
};

export default connect(null, actionCreators)(ActionCreator);
