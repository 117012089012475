import PropTypes from 'prop-types';
import { InputAdornment, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Info from '@mui/icons-material/InfoRounded';

const useStyles = makeStyles()(theme => ({
  tooltip: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
}));

function FilterInfo({ message }) {
  const { classes } = useStyles();
  return (
    <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
      <Tooltip classes={classes} title={message} placement="bottom">
        <Info color="info" />
      </Tooltip>
    </InputAdornment>
  );
}
FilterInfo.propTypes = {
  message: PropTypes.string,
};

FilterInfo.defaultProps = {
  message: null,
};

export default FilterInfo;
