export type OnFieldChange<FV> = <K extends keyof FV = any, A extends any[] = any[]>(
  fieldName: K,
  valueSelector?: (...args: A) => FV[K]
) => (...args: A) => void;

const identity = (...args: any[]) => args[0];

function useFieldChange<FormValue = any>(
  formValue: FormValue,
  onFormChange: (nextValue: any) => void
) {
  const onFieldChange: OnFieldChange<FormValue> = (fieldName, valueSelector = identity) => (
    ...args
  ) =>
    onFormChange({
      ...formValue,
      [fieldName]: valueSelector(...args),
    });

  return onFieldChange;
}

export default useFieldChange;
