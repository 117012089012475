import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Typography, IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import utils from 'src/utils/utils';
import CustCard from './ActionCard';
import { COMMERCIAL_RELAUNCH, MESSAGE_EDITOR, MESSAGE_MODEL } from './CampaignMessage';
import { useDispatch, useSelector } from 'react-redux';
import ActionType from 'src/enums/actionType';

const useStyles = makeStyles()(theme => ({
  addBtn: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.contrastText,
  },
}));

const ActionCreator = ({
  loadFieldsAndContent,
  rmTypeId,
  rmId,
  actionTypeId,
  goToCmpMsgStep,
  lang,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentAppLn = useSelector(({ app }) => app.currentLanguage);

  const onCreateXplorActiveNotif = useCallback(() => {
    loadFieldsAndContent(async processedFields => {
      await dispatch({
        type: 'RECEIVE_XPLACTIV_NOTIF_CONTENT',
        value: {
          id: null,
          messageId: null,
          object: '',
          content: '',
          type: actionTypeId,
          language: currentAppLn.shortName,
        },
      });
      goToCmpMsgStep(MESSAGE_EDITOR, {
        fields: processedFields,
        actionTypeId,
      });
    });
  }, [actionTypeId, currentAppLn.shortName, dispatch, goToCmpMsgStep, loadFieldsAndContent]);

  const onCreateAddCommercialRelaunch = useCallback(() => {
    dispatch({
      type: 'ON_RECEIVE_COMMERCIAL_RELAUNCH_CONFIG',
      value: {
        id: null,
        comment: null,
        date: 0,
        prospectingState: null,
        prospectingType: null,
        salePersonId: null,
        type: actionTypeId,
        language: currentAppLn.shortName,
      },
    });
    goToCmpMsgStep(COMMERCIAL_RELAUNCH, {
      fields: {},
      actionTypeId,
    });
  }, [actionTypeId, currentAppLn.shortName, dispatch, goToCmpMsgStep]);

  const onCreate = useCallback(() => {
    if (ActionType.PUSH_XPLORACTIVE.id === actionTypeId) {
      onCreateXplorActiveNotif();
    } else if (ActionType.PROSPECTING.id === actionTypeId) {
      onCreateAddCommercialRelaunch();
    } else {
      goToCmpMsgStep(MESSAGE_MODEL, { rmTypeId, rmId, actionTypeId, loadFieldsAndContent });
    }
  }, [
    actionTypeId,
    goToCmpMsgStep,
    loadFieldsAndContent,
    onCreateAddCommercialRelaunch,
    onCreateXplorActiveNotif,
    rmId,
    rmTypeId,
  ]);
  return (
    <CustCard title={utils.getLang(lang.title[actionTypeId])}>
      <Paper style={{ height: '200px', width: '160px', display: 'inline-block' }}>
        <Typography
          color="primary"
          style={{
            wordWrap: 'normal',
            padding: '8px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {utils.getLang(lang.createText[actionTypeId])}
        </Typography>

        <IconButton
          onClick={e => {
            e.preventDefault();
            onCreate();
          }}
          className={classes.addBtn}
          size="medium"
        >
          <AddIcon />
        </IconButton>
      </Paper>
    </CustCard>
  );
};

ActionCreator.propTypes = {
  loadFieldsAndContent: PropTypes.func,
  actionTypeId: PropTypes.number.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  rmId: PropTypes.number.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

ActionCreator.defaultProps = {
  loadFieldsAndContent: () => {},
};

export default ActionCreator;
