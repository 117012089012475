import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import IntegerFormat, { NaturalNumberFormat } from './IntegerFormat';

const IntegerField = ({
  style = { width: '100%' },
  value,
  onChange,
  InputProps,
  allowNegative = true,
  ...otherProps
}) => (
  <TextField
    style={style}
    onChange={event => {
      event.preventDefault();
      onChange(event.target.value);
    }}
    InputProps={{
      inputComponent: allowNegative ? IntegerFormat : NaturalNumberFormat,

      'aria-label': 'Description',
      ...InputProps,
    }}
    value={value}
    {...otherProps}
  />
);

IntegerField.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,

  InputProps: PropTypes.object,
};
IntegerField.defaultProps = {
  style: { width: '100%' },
  InputProps: {},
};

export default IntegerField;
