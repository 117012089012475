import CivilityType from './civilityType';
import StatusType from './statusType';
import PeriodType from './periodType';
import Channel from './channel';
import MandateRejectionType from './mandateRejectionType';
import ContactChannel from './contactChannel';
import CustomerRisk from './customerRisk';

const FinalizationType = {
  DISCONTINUE: 'created',
  NEXTSTEP: 'next_step',
  VALIDATE: 'validate',
  RESCHEDULE: 'rescheduled',
};

const RelanceCoState = {
  CREATED: 'created',
  FINALIZED: 'finalized',
};

export const ContactHeat = {
  HOT: 'hot',
  WARM: 'warm ',
  COLD: 'cold',
  BLOW_OUT: 'blow_out',
};

export default {
  CivilityType,
  StatusType,
  PeriodType,
  Channel,
  MandateRejectionType,
  ContactChannel,
  CustomerRisk,
  FinalizationType,
  RelanceCoState,
  ContactHeat,
};
