import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  SelectProps,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntegerField from 'src/components/common/IntegerField';
import useDerivedState from 'src/hooks/useDerivedState';
import useFetch from 'src/hooks/useFetch';
import { saveActionContent } from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import { useAlert } from 'src/hooks';
import { ContactHeat } from 'src/enums/enums';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';

const PROSPECT_HEAT = Object.keys(ContactHeat);

const selectPropsValue: Pick<SelectProps<unknown>, 'MenuProps'> = {
  MenuProps: {
    MenuListProps: { style: { padding: 0, margin: 0 } },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },

    marginThreshold: 0,
  },
};

type SingleProspectingState = typeof PROSPECT_HEAT[number];

interface HeatSelectorProps {
  onChange: (nextValue: SingleProspectingState) => void;
  value: SingleProspectingState | null;
}

const HeatSelector = ({ onChange, value }: HeatSelectorProps) => (
  <TextField
    fullWidth
    onChange={event => onChange(event.target.value as SingleProspectingState)}
    select
    value={value ?? ''}
    InputProps={{
      id: 'prospectingType',
    }}
    SelectProps={selectPropsValue}
  >
    {!value && <MenuItem value="" disabled />}

    {PROSPECT_HEAT.map(prospectingTypeName => (
      <MenuItem key={prospectingTypeName} value={ContactHeat[prospectingTypeName]}>
        {utils.getLang(
          `smartmessaging.prospectingForm.fields.prospectHeat.selectValue.${prospectingTypeName}`
        )}
      </MenuItem>
    ))}
  </TextField>
);

interface CommercialRelaunchState {
  comment?: string;
  date?: string;
  defaultProspectingState?: SingleProspectingState | null;
  forceDefaultHeatValue?: boolean;
  keepHeatValue?: boolean;
  prospectingState?: SingleProspectingState | null;
  prospectingStateStrategy?: 'absolute' | 'relative';
  prospectingType?: number;
  salePersonId?: number;
}

interface User {
  id: number;
  familyName: string;
  givenName: string;
}

interface CommercialRelaunchEditorProps {
  isMassAction?: boolean;
  onCancel: () => void;
}

const CommercialRelaunchEditor = ({
  onCancel,
  isMassAction = false,
}: CommercialRelaunchEditorProps) => {
  const currentAction = useSelector(({ campaignEditor, massAction }) =>
    isMassAction ? massAction.commercialRelaunch : campaignEditor.actions.currentAction
  );
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useDerivedState<CommercialRelaunchState>(
    () => {
      const prospectingStateStrategy = currentAction?.defaultProspectingState
        ? 'relative'
        : 'absolute';

      return { ...currentAction, prospectingStateStrategy };
    },
    [currentAction],
    nextFormValue =>
      dispatch({
        type: `ON_${isMassAction ? 'MASS_ACTION_' : ''}COMMERCIAL_RELAUNCH_CONFIG_CHANGE`,
        nextValue: nextFormValue,
      })
  );
  const contentValidity = contentEditorUtils.checkCommercialRelaunchContentValidity(formValue);

  const { data: userList } = useFetch<User[]>({ url: '/user/all' });
  const { selectedUser, userOptions } = useMemo(() => {
    const tmpUserOptions = (userList ?? []).map(user => ({
      id: user.id,
      fullName: `${user.familyName} ${user.givenName}`,
    }));
    const tmpSelectedUser =
      formValue.salePersonId && tmpUserOptions.find(user => user.id === formValue.salePersonId);

    return {
      selectedUser: tmpSelectedUser || null,
      userOptions: tmpUserOptions,
    };
  }, [formValue.salePersonId, userList]);

  const { data: commercialRelaunchTypeList } = useFetch<{ id: number; title: string }[]>({
    url: '/prospectingtype/all',
  });
  const { selectedType, commercialRelaunchTypeOptions } = useMemo(() => {
    const tmpCommercialRelaunchTypeOptions = (commercialRelaunchTypeList ?? []).map(type => ({
      id: type.id,
      title: type.title,
    }));
    const tmpSelectedUser =
      formValue.prospectingType &&
      tmpCommercialRelaunchTypeOptions.find(type => type.id === formValue.prospectingType);

    return {
      selectedType: tmpSelectedUser || null,
      commercialRelaunchTypeOptions: tmpCommercialRelaunchTypeOptions,
    };
  }, [commercialRelaunchTypeList, formValue.prospectingType]);

  const onHeatStrategyChange = event =>
    setFormValue({
      ...formValue,
      prospectingStateStrategy: event.target.value,
      ...(event.target.value === 'absolute'
        ? { defaultProspectingState: null, prospectingState: PROSPECT_HEAT[0] }
        : { defaultProspectingState: PROSPECT_HEAT[0], prospectingState: null }),
    });

  const { showAlert } = useAlert();

  const onSave = () =>
    dispatch(
      saveActionContent(() => {
        onCancel();
        dispatch({ type: 'CLEAR_CURRENT_ACTION' });
      }, showAlert) as any
    );

  return (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: `${spacing(3)} 15% ${spacing(1)}`,
        overflow: 'hidden',
      })}
    >
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden',
          textAlign: 'left',
        }}
      >
        <FormLabel component="h2" required htmlFor="date">
          {utils.getLang('smartmessaging.prospectingForm.fields.date')}
        </FormLabel>
        <IntegerField
          allowNegative={false}
          InputProps={{
            id: 'date',
          }}
          value={formValue.date ?? ''}
          onChange={(nextDate: string) =>
            setFormValue({
              ...formValue,
              date: nextDate,
            })
          }
        />
        <hr />
        <Alert severity="info">
          {utils.getLang('smartmessaging.prospectingForm.fields.salePerson.info')}
        </Alert>
        <FormLabel component="h2" htmlFor="salePerson">
          {utils.getLang('smartmessaging.prospectingForm.fields.salePerson')}
        </FormLabel>
        <Autocomplete
          getOptionLabel={option => option.fullName}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_event, nextOption) => {
            setFormValue({
              ...formValue,
              salePersonId: nextOption ? nextOption.id : null,
            });
          }}
          options={userOptions}
          renderInput={params => <TextField {...params} />}
          value={selectedUser}
        />
        <hr />
        <FormLabel component="h2" required htmlFor="prospectingType">
          {utils.getLang('smartmessaging.prospectingForm.fields.prospectingType')}
        </FormLabel>
        <Autocomplete
          getOptionLabel={option => option.title}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_event, nextOption) => {
            setFormValue({
              ...formValue,
              prospectingType: nextOption ? nextOption.id : null,
            });
          }}
          options={commercialRelaunchTypeOptions}
          renderInput={params => <TextField {...params} />}
          value={selectedType}
        />
        <hr />
        <FormLabel component="h2" required htmlFor="prospectingType">
          {utils.getLang('smartmessaging.prospectingForm.fields.prospectHeat')}
        </FormLabel>
        <RadioGroup
          value={formValue.prospectingStateStrategy ?? 'absolute'}
          onChange={onHeatStrategyChange}
        >
          <Box display="grid" gridTemplateColumns="1fr 1fr" width="100%">
            <FormControlLabel
              control={<Radio />}
              value="absolute"
              label={utils.getLang('smartmessaging.prospectingForm.fields.prospectHeat.force')}
              labelPlacement="end"
            />
            <HeatSelector
              onChange={nextValue =>
                setFormValue({
                  ...formValue,
                  defaultProspectingState: null,
                  prospectingState: nextValue,
                  prospectingStateStrategy: 'absolute',
                })
              }
              value={formValue.prospectingState}
            />
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" width="100%">
            <FormControlLabel
              control={<Radio />}
              value="relative"
              label={utils.getLang(
                'smartmessaging.prospectingForm.fields.prospectHeat.defaultHeat'
              )}
              labelPlacement="end"
            />
            <HeatSelector
              onChange={nextValue =>
                setFormValue({
                  ...formValue,
                  defaultProspectingState: nextValue,
                  prospectingState: null,
                  prospectingStateStrategy: 'relative',
                })
              }
              value={formValue.defaultProspectingState ?? null}
            />
            {formValue.prospectingStateStrategy === 'relative' && (
              <Typography sx={{ gridColumn: 'span 2', pl: 4 }} variant="body2">
                * {utils.getLang('smartmessaging.prospectingForm.fields.prospectHeat.subInfo')}
              </Typography>
            )}
          </Box>
        </RadioGroup>
        <hr />
        <FormLabel component="h2" htmlFor="prospectingComment">
          {utils.getLang('smartmessaging.prospectingForm.fields.comment')}
        </FormLabel>
        <TextField
          onChange={event =>
            setFormValue({
              ...formValue,
              comment: event.target.value,
            })
          }
          InputProps={{
            id: 'prospectingComment',
          }}
          value={formValue.comment ?? ''}
        />
      </Paper>

      <Box sx={{ textAlign: 'right', pt: 2 }}>
        <Button style={{ margin: '4px' }} variant="text" color="primary" onClick={onCancel}>
          {utils.getLang('smartmessaging.buttonLabel.previous')}
        </Button>
        {!isMassAction && (
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '4px' }}
            disabled={!contentValidity.isValid}
            onClick={onSave}
          >
            {utils.getLang('smartmessaging.button.contentEditor.save')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CommercialRelaunchEditor;
