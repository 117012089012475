import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import MessageTemplateSelector from './MessageTemplateSelector';
import MessageContentEditor from './MessageContentEditor';
import CommercialRelaunchEditor from 'src/components/campaign/campaignEditor/editorComponents/campaignContent/CommercialRelaunchEditor';
import { CONTENT_SELECTOR } from '../MassActionContent';
import MessageActionAppBar from './MessageActionAppBar';

export const [TEMPLATE_SELECTOR, CONTENT_EDITOR, COMMERCIAL_RELAUNCH] = [0, 1, 2];

function PageContent({ step, ...otherProps }) {
  switch (step) {
    case TEMPLATE_SELECTOR:
      return <MessageTemplateSelector {...otherProps} />;
    case CONTENT_EDITOR:
      return <MessageContentEditor {...otherProps} />;
    case COMMERCIAL_RELAUNCH:
      return (
        <>
          <MessageActionAppBar
            setStep={otherProps.setStep}
            goToStep={otherProps.goToStep}
            actionType={otherProps.actionType}
            fields={otherProps.fields}
            actionId={otherProps.actionId}
            isWritable={otherProps.isWritable}
          />
          <CommercialRelaunchEditor
            isMassAction
            onCancel={() => {
              otherProps.goToStep(CONTENT_SELECTOR);
            }}
            {...otherProps}
          />
        </>
      );
    default:
      return null;
  }
}

PageContent.propTypes = {
  step: PropTypes.number.isRequired,
};

function MassActionMessage({
  initialStep,
  initialExtraProps,
  actionType,
  actionId,
  goToStep,
  isWritable,
}) {
  const [step, setStep] = useState(initialStep); // --> message step, not action co step
  const [extraProps, setExtraProps] = useState(initialExtraProps);

  const enhancedSetStep = useCallback((stepId, newExtraProps) => {
    setExtraProps(newExtraProps || {});
    setStep(stepId);
  }, []);

  return (
    <PageContent
      step={step}
      setStep={enhancedSetStep}
      goToStep={goToStep}
      actionType={actionType}
      actionId={actionId}
      isWritable={isWritable}
      {...extraProps}
    />
  );
}

MassActionMessage.propTypes = {
  actionType: PropTypes.number,
  actionId: PropTypes.number,
  initialStep: PropTypes.number,
  initialExtraProps: PropTypes.object,
  goToStep: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

MassActionMessage.defaultProps = {
  actionType: null,
  actionId: null,
  initialStep: 0,
  initialExtraProps: {},
};

export default MassActionMessage;
